.image-grids-image {
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 90vh;
  position: absolute;
  margin: 10px 0px;
}
.image-grids-image img {
  visibility: hidden;
  max-height: 90vh;
}

/* GRIDS */
.grids-wrapper {
  position: absolute;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.grids-wrapper .grid {
  margin: -2px;
  border: 2px solid red;
}
