#copyright {
  position: absolute;
  bottom: 10px;
}

@media only screen and (min-width: 1240px) {
  #copyright {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
