.image-grids-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#no-img-loaded {
  color: #1976d2;
  text-align: center;
  margin-top: 10%;
}
