.image-grids-controls {
  display: flex;
  /* padding: 10px 0px; */
  padding-top: 10px;
  width: 40%;
}
.image-grids-controls > div {
  margin: 0 10px;
}
#uploadInput {
  display: none;
}

@media only screen and (max-width: 1240px) {
  .image-grids-controls {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .image-grids-controls {
    width: 95%;
  }
}
